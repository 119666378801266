<template>
  <div class="tlpay">
    <div class="dl_title">
      <img src="../assets/img/logo_new.png" mode="aspectFit" class="fontimg" />
    </div>
    <div class="zc_box">
      <div class="title_name">
        <div class="top_name">
          <div class="name_1"></div>
          <div class="name_2">手机号:</div>
        </div>
        <input class="input_box" placeholder="请输入联系方式" type="number" v-model="form.mobile" />
        <div class="top_name">
          <div class="name_1"></div>
          <div class="name_2">金额:</div>
        </div>
        <input class="input_box" placeholder="请输入付款金额（单位：元）" v-model="form.money" type="number"
          onkeyup="value=value.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')" />
        <textarea class="textarea-box" placeholder="请输入备注" v-model="form.remark"></textarea>
      </div>
      <div class="zc_btn" @click="submit">付款</div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      form: {},
    };
  },
  created() {
    this._code = this.$route.query.code;
    // this._openid = "o14vn6d4ZH57UIhe1a-3OGMr9_kY";
    if (!this._openid) {
      // alert("请求openid");
      this.requestOpenid();
    }
  },
  methods: {
    submit() {
      if (!this.form.mobile) {
        alert("请输入手机号");
        return;
      }
      // var telStr =
      //   /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
      // if (!telStr.test(this.form.mobile)) {
      //   alert("手机号码输入不规范");
      //   return;
      // }
      if (this.form.mobile.length != 11) {
        alert("手机号码输入不规范");
        return;
      }
      if (!this.form.money) {
        alert("请输入付款金额");
        return;
      }
      // 保留2位小数
      this.form.money = Number(this.form.money).toFixed(2)
      if (this.form.money < 1) {
        alert("金额需要大于1");
        return;
      }
      this.form.openid = this._openid;
      Toast.loading({ duration: 60000 });

      this.$http.tlpay(this.form).then((res) => {
        console.log(res);
        Toast.clear();
        if (res.code == 200) {
          this.form = {};
          var data = res.data;
          var da = {};
          da.appId = "wxad025ddb8e0d607a";
          da.nonceStr = data.remark;
          da.package = data.quickRspString;
          da.signType = data.sgnTp;
          da.paySign = data.signCert;
          da.timeStamp = data.timestamp;
          if (typeof window.WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener(
                "WeixinJSBridgeReady",
                function () {
                  this.onBridgeReady(da);
                },
                false
              );
            } else if (document["attachEvent"]) {
              document["attachEvent"]("WeixinJSBridgeReady", function () {
                this.onBridgeReady(da);
              });
              document["attachEvent"]("onWeixinJSBridgeReady", function () {
                this.onBridgeReady(da);
              });
            }
          } else {
            this.onBridgeReady(da);
          }
        } else {
          alert(res.msg);
        }
      });
    },
    requestOpenid() {
      this.$http.getTlOpenid({ code: this._code }).then((res) => {
        if (res.code == 200) {
          this._openid = res.data.openid;
          return;
        }
      });
    },
    onBridgeReady(da) {
      window.WeixinJSBridge.invoke("getBrandWCPayRequest", da, (res) => {
        Toast.clear();
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          console.log("111");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tlpay {
  width: 100%;
  min-height: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  /* justify-content: space-between; */
  .dl_title {
    margin-top: 26px;
    width: 120px;
    height: 120px;
    .fontimg {
      width: 100%;
      height: 100%;
    }
  }
  .zc_box {
    width: 335px;
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .title_name {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top_name {
        width: 100%;
        height: 22px;
        display: flex;
        margin-bottom: 8px;
        .name_1 {
          margin-left: 16px;
          width: 4px;
          height: 14px;
          background: #0070fb;
          border-radius: 2px;
        }
        .name_2 {
          font-size: 16px;
          font-weight: 500;
          color: #222222;
          margin-left: 8px;
        }
      }
      .input_box {
        font-size: 14px;
        width: 303px;
        height: 48px;
        background: #f3f7fa;
        border-radius: 8px;
        padding-left: 16px;
        box-sizing: border-box;
        margin-bottom: 24px;
      }
      .zhu {
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 400;
        color: #cecece;
        margin-bottom: 28px;
      }
    }
    .textarea-box {
      font-size: 14px;
      width: 303px;
      height: 100px;
      background: #f3f7fa;
      border-radius: 8px;
      box-sizing: border-box;
      margin-bottom: 24px;

      padding: 16px;
    }
    .zc_btn {
      width: 303px;
      height: 48px;
      border-radius: 24px;
      margin-bottom: 32px;
      text-align: center;
      font-weight: 500;
      color: #ffffff;
      font-size: 18px;
      line-height: 48px;
      background-color: #0070fb;
    }
  }
}
</style>
